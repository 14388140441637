import React from 'react'
import { Link } from "gatsby"
import { RichText } from "prismic-reactjs"
import PropTypes from "prop-types"

import Icon from "../../svg/concord-icon.svg"

import { linkResolver } from '../../utils/linkResolver'
import ArrowLink from '../arrowLink/arrowLink'

import styles from './policiesModule.module.scss'
 
const PoliciesModule = ( props ) => (
  <div className={`section bg-grey`} id="policies">
    <div className="cols-wrapper">
      <div className={`cols`}>
        <div className={`col-8-md col-6-lg`}>
          <h3 className={`t-h2 ${props.color}`}>Policies</h3>
          {props.policies &&
            <div className={`${styles.grid} cols-wrapper ${styles.policies}`}>
              <div className={`cols cols-wrap ${styles.cols}`}>
                {props.policies.map((item, index) => {
                  if (!item.policy) {
                    return null
                  }

                  return (
                    <div 
                      className={`col-2-md col-2-lg ${styles.col}`}
                      key={index}
                    >
                      <Link to={linkResolver(item.policy._meta)}>
                        <div className={styles.thumbnail}>
                          <div className={styles.thumbnailInner}>
                            {item.policy.policy_cover_thumbnail ? 
                              <img src="" data-src={`${item.policy.policy_cover_thumbnail.url}&w=500&fit=clip`} className="lazyload" alt={RichText.asText(item.policy.title)} />
                            : 
                              <>
                                <Icon />
                                <h3 className={`t-h4 t-color-white ${styles.title}`}>
                                  {RichText.asText(item.policy.title)}
                                </h3>
                              </>
                            }
                          </div>
                        </div>
                        <div className={styles.button}>
                          <div className="arrow-link">
                            <span className="label">Learn more</span>
                            <span className="icon">
                              <span className="icon__bar" />
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
                })}
              </div>
            </div>
          }
          {!!props.section.primary.section_text &&
            <div className={`t-body text-body t-color-blue`}>
              <RichText
                render={props.section.primary.section_text}
                linkResolver={linkResolver}
              />
            </div>
          }
          <div className={styles.pastResolutions}>
            <ArrowLink
              to='/past-resolutions'
            >
              Past Modern Slavery Statements
            </ArrowLink><br />
            <ArrowLink
              to='/past-resolutions'
            >
              Past ESG Statements
            </ArrowLink>
          </div>
        </div>
      </div>
    </div>
  </div>
)

PoliciesModule.propTypes = {
  section: PropTypes.object,
  policies: PropTypes.array,
  color: PropTypes.string
}

PoliciesModule.defaultProps = {
  policies: [],
  color: "color-blue"
}
 
export default PoliciesModule;