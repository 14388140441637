import React from 'react'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"
  
import { linkResolver } from '../../utils/linkResolver'

import Icon from "../../svg/icon-arrow.svg"

import styles from './servicesPagination.module.scss'

function prevIndex (i, arr) {
  if (i === 0) {
    i = arr.length
  }

  i = i - 1

  return arr[i]
}

function nextIndex (i, arr) {
  i = i + 1
  i = i = i % arr.length

  return arr[i]
}

const getLink = ( uid, services, direction ) => {
  let target 

  const index = services.findIndex(x => x.service._meta.uid === uid)

  if (direction === 'next') {
    target = nextIndex(index, services)
  } else if (direction === 'prev') {
    target = prevIndex(index, services)
  }

  return (
    <Link className={`t-uc ${styles[direction]}`} to={linkResolver(target.service._meta)}>
      <div className={styles.icon}>
        <Icon />
      </div>
      <div className={styles.label}>
        <span>View </span>{RichText.asText(target.service.title)}
      </div>
    </Link>
  )
}
 
const ServicesPagination = ( props ) => (
  <div className={`${styles.wrapper} bg-blue-highlight t-color-white`}>
    <div className={`limit-width ${styles.pagination}`}>
      {getLink(props.uid, props.services, 'prev')}
      {getLink(props.uid, props.services, 'next')}
    </div>
  </div>
)

ServicesPagination.propTypes = {
  uid: PropTypes.string,
  services: PropTypes.array
}

export default ServicesPagination;