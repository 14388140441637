import React from "react"
import { RichText } from "prismic-reactjs"
import PropTypes from "prop-types"

import ResponsiveImage from '../responsiveImage/responsiveImage'

import styles from './caseStudies.module.scss'

const CaseStudiesBody = ({ doc, theme }) => (
  <React.Fragment>
    <div className="cols-wrapper">
      <div className="cols">
        <div className={`col-12-md col-10-lg push-1-lg ${styles.container}`}>
          {doc.lead_image !== null && 
            <div className={styles.leadImage}>
              <div className={styles.leadImageInner}>
                <ResponsiveImage
                  src={doc.lead_image.url}
                  width={doc.lead_image.dimensions.width}
                  height={doc.lead_image.dimensions.height}
                  alt={doc.lead_image.alt}
                  widths={['2200', '1500', '750']}
                />
              </div>
            </div>
          }
          <div className={styles.header}>
            <h1 className={`t-h2 ${theme === "dark" ? `t-color-white` : 't-color-blue'}`}>
              {RichText.asText(doc.title)}
            </h1>
          </div>
          <div className={styles.body}>
            <div className="cols-wrapper">
              <div className={`cols ${styles.cols}`}>
                <div className={`col-6-md col-5-lg ${styles.col}`}>
                  <h3 className={`t-h4 section-title ${theme === "dark" ? `t-color-white` : 't-color-blue'}`}>
                    Overview
                  </h3>
                  <div className="t-body-1 text-body">
                    {RichText.render(doc.overview_text)}
                  </div>
                </div>
                <div className={`col-5-md push-1-md col-4-lg push-1-lg ${styles.col}`}>
                  <h3 className={`t-uc t-strong section-title ${theme === "dark" ? `t-color-white` : 't-color-blue'}`}>
                    Delivered
                  </h3>
                  <div className="t-body-2 text-body">
                    {RichText.render(doc.deliverables_list)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
)

CaseStudiesBody.propTypes = {
  doc: PropTypes.object,
  theme: PropTypes.string
}

CaseStudiesBody.defaultProps = {
  doc: {},
  theme: 'light'
}

export default CaseStudiesBody
