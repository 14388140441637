import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"

import ArrowLink from '../arrowLink/arrowLink'
 
const ServicesContact = ( props ) => (
  <div className="section bg-blue-highlight t-color-white">
    <div className="cols-wrapper">
      <div className="cols">
        <div className={`col-6-md col-5-lg`}>
          <h3 className={`t-h2 section-title t-color-white`}>Contact {RichText.asText(props.title)} Services</h3>
          <div className={`t-body text-body`}>
            <p>If you have any questions regarding our {RichText.asText(props.title)} services please contact us via the email link below.</p>
          </div>
          <div className="button-block">
            <ArrowLink
              to="/contact"
              color="white"
              button="outline-white"
            >
              Contact us
            </ArrowLink>
          </div>
        </div>
      </div>
    </div>
  </div>
)

ServicesContact.propTypes = {
  title: PropTypes.array
}

ServicesContact.defaultProps = {
  title: []
}
 
export default ServicesContact;