import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from "prop-types"
import VisibilitySensor from 'react-visibility-sensor'
 
const VisibilityWrapper = ( props ) => {
  const [visible, setVisible] = useState(false)

  function onChange (isVisible) {
    if (isVisible) {
      props.setActiveNavSection(props.title)
      props.setBackgroundColor(props.backgroundColor)
      setVisible(true)
    } else {
      setVisible(false)
    }
  }

  function returnChild () {
    return (
      <React.Fragment>
        {props.children}
        {props.backgroundImage && 
          <div className={`section-image ${visible ? 'is-visible' : ''}`}>
            <img src={props.backgroundImage} />
          </div>
        }
      </React.Fragment>
    )
  }

  return (
    <VisibilitySensor onChange={onChange} scrollCheck={true} intervalCheck={false} partialVisibility={true} offset={{top: props.windowHeight / 2, bottom: props.windowHeight / 2}} scrollThrottle={50}>
      {returnChild()}
    </VisibilitySensor>
  )
}

VisibilityWrapper.propTypes = {
  title: PropTypes.string,
  backgroundColor: PropTypes.string,
  windowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  backgroundImage: PropTypes.string
}

VisibilityWrapper.defaultProps = {
  windowHeight: 0,
  headerHeight: null
}
 
const mapDispatchToProps = dispatch => {
  return {
    setActiveNavSection: (val) => dispatch({ type: `SET_ACTIVENAVSECTION`, value: val }),
    setBackgroundColor: (val) => dispatch({ type: `SET_BACKGROUNDCOLOR`, value: val })
  }
}

const mapStateToProps = ({ windowHeight, headerHeight }) => {
  return { windowHeight, headerHeight }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisibilityWrapper)