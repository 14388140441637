import React from "react"
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"

import VisibilityWrapper from '../../components/visibilityWrapper/visibilityWrapper'
import PageHeader from '../../components/pageHeader/pageHeader'
import PageTextSection from '../../components/pageTextSection/pageTextSection'
import CommoditiesSection from '../../components/commoditiesSection/commoditiesSection'
import CaseStudies from '../../components/caseStudies/caseStudies'
import PoliciesModule from '../../components/policiesModule/policiesModule'
import PageNav from '../../components/pageNav/pageNav'

import { createHandle } from '../../utils/createHandle'

const renderPageNav = (items, pageNavParent, pageNavSiblings) => {
  return (
    <PageNav
      className="t-uc-sm t-strong"
      items={items()}
      pageNavParent={pageNavParent}
      pageNavSiblings={pageNavSiblings}
    />
  )
}
 
const SectionedPageContent = ({ doc, pageNavParent, pageNavSiblings }) => {
  return (
    <React.Fragment>
      <VisibilityWrapper title="Overview" backgroundColor="dark">
        <PageHeader
          title={doc.node.title}
          text={doc.node.introduction_text}
          id="overview"
          background={doc.node.background_video}
          fixedMode={true}
        />
      </VisibilityWrapper>
      <div>
        {renderPageNav(() => {
          const arr = [
            {
              title: 'Overview',
              shortTitle: null
            }
          ]

          const sections = doc.node.body !== null ? doc.node.body.map((section, index) => {
            if (section.type === 'text_section' || section.type === 'commodities_section') {
              if (section.primary.section_title === null || section.primary.disable_page_link === true) {
                return null
              } else {
                return {
                  title: section.primary.section_title[0].text,
                  shortTitle: section.primary.short_section_title
                }
              }
            } else if (section.type === 'case_studies') {
              return {
                title: 'Case Studies',
                shortTitle: null
              }
            } else if (section.type === 'policies') {
              return {
                title: 'Policies',
                shortTitle: null
              }
            } else {
              return null
            }
          }) : null

          return sections !== null ? arr.concat(sections) : null
        }, pageNavParent, pageNavSiblings)}
        {doc.node.body !== null ? doc.node.body.map((section, index) => {
          switch (section.type) {
            case 'text_section':
              return (
                <VisibilityWrapper title={section.primary.section_title !== null ? RichText.asText(section.primary.section_title) : ''} backgroundColor="dark" key={index} backgroundImage={section.primary.background_image ? section.primary.background_image.url  : null}>
                  <PageTextSection section={section} id={section.primary.short_section_title !== null ? createHandle(section.primary.short_section_title) : section.primary.section_title !== null ? createHandle(section.primary.section_title[0].text) : ''} />
                </VisibilityWrapper>
              )
            case 'commodities_section':
              return (
                <VisibilityWrapper title={section.primary.section_title !== null ? RichText.asText(section.primary.section_title) : ''} backgroundColor="dark" key={index} backgroundImage={section.primary.background_image ? section.primary.background_image.url  : null}>
                  <CommoditiesSection section={section} id={section.primary.section_title !== null ? createHandle(section.primary.section_title[0].text) : ''} />
                </VisibilityWrapper>
              )
            case 'case_studies':
              return (
                <VisibilityWrapper title="Case Studies" backgroundColor="dark" key={index}>
                  <CaseStudies caseStudies={section.fields} />
                </VisibilityWrapper>
              )
            case 'policies':
              return (
                <VisibilityWrapper title="Policies" backgroundColor="light" key={index}>
                  <PoliciesModule section={section} policies={section.fields} />
                </VisibilityWrapper>
              )
            default:
              return null
          }
        }) : null}
      </div>
    </React.Fragment>
  )
}

SectionedPageContent.propTypes = {
  doc: PropTypes.object,
  pageNavParent: PropTypes.object,
  pageNavSiblings: PropTypes.array
}

SectionedPageContent.defaultProps = {
  pageNavParent: null,
  pageNavSiblings: null
}

export default SectionedPageContent