import React from 'react'
import PropTypes from "prop-types"

import CaseStudiesSingle from './caseStudiesSingle'

import Icon from "../../svg/concord-icon.svg"

import styles from './caseStudies.module.scss'
 
const CaseStudies = ( props ) => (
  <div className={`section ${styles.section} bg-blue`} id="case-studies">
    <div className={`cols-wrapper ${styles.wrapper}`}>
      <div className={`cols ${styles.inner}`}>
        <div className={`col-12-md col-12-lg`}>
          <h3 className={`t-h2 t-color-white ${styles.title}`}>Case Studies</h3>
          <div className={`t-body-0 t-color-white`}>
            <ul>
              {props.caseStudies.map((item, index) => {
                if (item.case_study === null) {
                  return null
                }

                return (
                  <CaseStudiesSingle key={index} caseStudy={item.case_study} /> 
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.background}>
        <Icon />
      </div>
    </div>
  </div>
)

CaseStudies.propTypes = {
  caseStudies: PropTypes.array,
  color: PropTypes.string
}

CaseStudies.defaultProps = {
  caseStudies: [],
  color: "color-blue"
}
 
export default CaseStudies;