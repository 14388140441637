import React, { useState, useEffect } from 'react'
import PropTypes from "prop-types"
import { connect } from 'react-redux'
import {gsap} from 'gsap'
import {ScrollToPlugin} from 'gsap/ScrollToPlugin.js'

import styles from './pageHeader.module.scss'
import { renderBackground } from '../../utils/renderBackground'

import PageHeaderInner from './pageHeaderInner'
 
const PageHeader = ( props ) => {
  const [scroll, setScroll] = useState(0)

  const { proceed = true } = props

  function scrollDistanceHandler () {
    setScroll(window.scrollY)
  }

  gsap.registerPlugin(ScrollToPlugin)

  function scrollToContent (container, e) {
    if (!container) {
      return
    }

    const parent = e.currentTarget.closest(`.${container}`)

    if (!parent) {
      return
    }

    gsap.to(window, {duration: 1, scrollTo: {y: parent.clientHeight, offsetY: props.headerHeight, autoKill: false}, ease: "power2.inOut"})
  }
  
  useEffect(() => {
    if (props.fixedMode === true) {
      window.addEventListener('scroll', scrollDistanceHandler)
    }

    return () => {
      if (props.fixedMode === true) {
        window.removeEventListener('scroll', scrollDistanceHandler)
      }
    }
  })

  return (
    <div className={`${styles.container} ${props.fixedMode === true ? styles.fixedMode : ''}`}>
      <PageHeaderInner
        background={props.background}
        title={props.title}
        text={props.text}
        id={props.id}
      >
        {props.button &&
          <button className={`button t-color-white button--white ${styles.headerButton}`} onClick={(e) => scrollToContent(styles.container, e)}>
            {props.button}
          </button>
        }
        {props.buttonLink &&
          <a
            className={`button t-color-white button--white ${styles.headerButton}`}
            href={props.buttonLink.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {props.buttonLink.text}
          </a>
        }
      </PageHeaderInner>
      {proceed === true &&
        <div className={`${styles.proceed} proceed-animate`}>
          <button className={styles.proceedButton} onClick={(e) => scrollToContent(styles.container, e)}>
            <svg width="25" height="16" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L12.5 13.6962L24 1" stroke="white" strokeWidth="2"/>
            </svg>
          </button>
        </div>
      }
      {props.background &&
        <div className={styles.background}>
          <div style={{
            opacity: scroll < (props.windowHeight / 2) ? ((1 - (scroll / (props.windowHeight / 2))) * (1 - 0.15)) + 0.15 : 0.15
          }}>
            {renderBackground(props.background)}
          </div>
        </div>
      }
    </div>
  )
}

PageHeader.propTypes = {
  text: PropTypes.array,
  title: PropTypes.array,
  id: PropTypes.string,
  background: PropTypes.object,
  headerHeight: PropTypes.number,
  fixedMode: PropTypes.bool
}

PageHeader.defaultProps = {
  text: [],
  title: [],
  headerHeight: 0,
  id: '',
  background: null,
  fixedMode: false
}

const mapStateToProps = ({ headerHeight, windowHeight }) => {
  return { headerHeight, windowHeight }
}

const mapDispatchToProps = dispatch => {
  return {
    setHeaderTransparent: (val) => dispatch({ type: `SET_HEADERTRANSPARENT`, value: val })
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)