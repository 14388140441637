import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"
import { linkResolver } from '../../utils/linkResolver'

const PageTextSection = ( props ) => {
  if (props.section.primary.section_title === null && props.section.primary.section_text === null) {
    return null
  }

  return (
    <div className={`section`} id={props.id}>
      <div className=" cols-wrapper">
        <div className="cols">
          <div className={`col-8-md col-7-lg`}>
            {!!props.section.primary.section_title &&
              <h3 className={`t-h2 section-title t-color-white ${props.color}`}>{RichText.asText(props.section.primary.section_title)}</h3>
            }
            {!!props.section.primary.section_text &&
              <div className={`t-body text-body t-color-white`}>
                <RichText
                  render={props.section.primary.section_text}
                  linkResolver={linkResolver}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

PageTextSection.propTypes = {
  section: PropTypes.object,
  color: PropTypes.string
}

PageTextSection.defaultProps = {
  section: {},
  color: "color-blue"
}
 
export default PageTextSection