import React, { Component } from 'react'
import Modal from 'react-modal'
import { Link } from "gatsby"
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"
import { CSSTransition } from 'react-transition-group'

import CaseStudiesBody from './caseStudiesBody'

import { linkResolver } from '../../utils/linkResolver'

import styles from './caseStudies.module.scss'

Modal.setAppElement('#___gatsby')
 
class CaseStudiesSingle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalOpen: false,
    }
  }

  handleModalOpen = event => {
    // console.log('handleModalOpen: ', event);
    this.setState({ isModalOpen: true })
    event.preventDefault()
  }

  handleModalClose = event => {
    // console.log('handleModalOpen: ', event);
    this.setState({ isModalOpen: false })
  }

  render () {
    return (
      <li>
        <Link
          to={linkResolver(this.props.caseStudy._meta)}
          onClick={this.handleModalOpen}
          className={styles.link}
        >
          {RichText.asText(this.props.caseStudy.title)}
        </Link>
        <Modal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.handleModalClose}
          className={styles.modal}
          overlayClassName={styles.overlay}
          closeTimeoutMS={350}
        >
          <div className="cols-wrapper">
            <div className="cols">
              <div className="col-12-md col-10-lg push-1-lg">
                <div className={styles.closeBar}>
                  <button
                    onClick={this.handleModalClose}
                    className={styles.closeButton}
                  >
                    <span className={styles.closeButtonIcon}>
                      <span />
                      <span />
                    </span>
                    <span className={styles.closeButtonLabel}>
                      Close
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <CaseStudiesBody doc={this.props.caseStudy} theme="dark" />
        </Modal>
      </li>
    )
  }
}

CaseStudiesSingle.propTypes = {
  caseStudy: PropTypes.object
}

CaseStudiesSingle.defaultProps = {
  caseStudy: []
}
 
export default CaseStudiesSingle;