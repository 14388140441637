import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"

import SectionedPageContent from '../components/sectionedPageContent/sectionedPageContent'
import ServicesContact from '../components/servicesContact/servicesContact'
import ServicesPagination from '../components/servicesPagination/servicesPagination'
  
export const query = graphql`
  query ServicesQuery($uid: String) {
    prismic {
      allServicess(uid: $uid) {
        edges {
          node {
            title
            introduction_text
            brand_colour
            background_video {
              ... on PRISMIC__ImageLink {
                _linkType
                url
                width
                height
              }
              ... on PRISMIC__FileLink {
                _linkType
                url
              }
            }
            _meta {
              uid
            }
            body {
              ... on PRISMIC_ServicesBodyText_section {
                type
                label
                primary {
                  section_text
                  section_title
                  short_section_title
                }
              }
              ... on PRISMIC_ServicesBodyCase_studies {
                type
                label
                fields {
                  case_study {
                    ... on PRISMIC_Case_studies {
                      title
                      lead_image
                      overview_text
                      deliverables_list
                      _meta {
                        uid
                        type
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      allHomepages {
        edges {
          node {
            services {
              service {
                ... on PRISMIC_Services {
                  title
                  _meta {
                  uid
                    type
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Service = ({ data }) => {
  const doc = data.prismic.allServicess.edges.slice(0,1).pop();
  const homepageDoc = data.prismic.allHomepages.edges.slice(0,1).pop();
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title="Services" />
      <SectionedPageContent
        doc={doc}
        pageNavParent={doc.node}
        pageNavSiblings={homepageDoc.node.services}
      />
      <ServicesContact title={doc.node.title} />
      <ServicesPagination uid={doc.node._meta.uid} services={homepageDoc.node.services} />
    </React.Fragment>
  )
}

export default Service
