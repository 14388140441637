import React from 'react'
import PropTypes from "prop-types"
import { Link } from "gatsby"
 
const ArrowLink = ( props ) => {
  const internal = /^\/(?!\/)/.test(props.to)

  if (internal) {
    return (
      <Link
        to={props.to}
        className={`arrow-link ${props.color ? `arrow-link--${props.color}` : 'arrow-link--blue'} ${props.button ? `arrow-button--${props.button}` : ''}`}
      >
        <span className="label">{props.children}</span>
        <span className="icon">
          <span className="icon__bar" />
        </span>
      </Link>
    )
  }
  return (
    <a
      href={props.to}
      target="_blank"
      rel="noopener noreferrer"
      className={`arrow-link ${props.color ? `arrow-link--${props.color}` : 'arrow-link--blue'} ${props.button ? `arrow-button--${props.button}` : ''}`}
    >
      <span className="label">{props.children}</span>
      <span className="icon">
        <span className="icon__bar" />
      </span>
    </a>
  )
}

ArrowLink.propTypes = {
  to: PropTypes.string,
  color: PropTypes.string,
  button: PropTypes.string
}

export default ArrowLink