import React, { Component } from 'react'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import gsap from 'gsap'
import { connect } from 'react-redux'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import PropTypes from "prop-types"
  
import { linkResolver } from '../../utils/linkResolver'
import { createHandle } from '../../utils/createHandle'
  
import styles from './pageNav.module.scss'
 
gsap.registerPlugin(ScrollToPlugin)

class PageNav extends Component {
  constructor(props) {
    super(props)

    this.pageNavRef = React.createRef()

    this.boundResizeFunction = null

    this.state = {
      navHeight: props.windowHeight
    }
  }

  scrollTo (el, e) {
    gsap.to(window, {duration: 0.75, scrollTo: {y: el, offsetY: this.props.headerHeight} })

    if (e) {
      e.preventDefault()
    }
  }

  reorderedArray (array, parent) {
    let reorderedArray = array.slice() 
    const index = reorderedArray.findIndex(x => x.service._meta.uid === parent._meta.uid)

    for (var i = 0; i <= index; i++) {
      if (i === index) {
        reorderedArray.shift()
      } else {
        reorderedArray.push(reorderedArray.shift())
      }
    }

    return reorderedArray
  }

  componentDidMount () {
    this.boundResizeFunction = this.resizeFunction.bind(this)

    window.addEventListener('resize', this.boundResizeFunction)

    this.setNavHeight()
  }

  componentDidUpdate(prevProps) {
    if (this.props.windowHeight !== prevProps.windowHeight) {
      this.setNavHeight()
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.boundResizeFunction)
  }

  setNavHeight () {
    if (this.pageNavRef.current) {
      if (this.pageNavRef.current.clientHeight < this.props.windowHeight) {
        this.setState({ navHeight: this.pageNavRef.current.clientHeight })
      } else {
        this.setState({ navHeight: this.props.windowHeight })
      }
    }
  }

  resizeFunction () {
    this.setNavHeight()
  }

  render () {
    if (this.props.items === null) {
      return null
    }

    return (
      <div
        className={`${styles.nav} t-uc-sm t-strong ${styles[this.props.backgroundColor]}`}
        style={{marginTop: `-${this.state.navHeight}px`}}
        ref={this.pageNavRef}
      >
        <ul className={styles.mainNav}>
          {this.props.items.map((item, index) => {
            if (!item) {
              return null
            }

            const title = item.title
            const shortTitle = item.shortTitle
            const target = `#${createHandle(shortTitle ?? title)}`
            
            return (
              <li key={index}>
                <a
                  className={this.props.activeNavSection === title ? styles.active : ''}
                  href={target}
                  onClick={(e) => this.scrollTo(target, e)}
                >
                  {shortTitle ?? title}
                </a>
              </li>
            )
          })}
        </ul>
        {(this.props.pageNavSiblings !== null && this.props.pageNavParent !== null) &&
          <ul className={`${styles.siblings} t-uc-xs t-strong`}>
            {this.reorderedArray(this.props.pageNavSiblings, this.props.pageNavParent).map((item, index) => (
              <li key={index}>
                <Link to={linkResolver(item.service._meta)}>
                  {RichText.asText(item.service.title)}
                </Link>
              </li>
            ))}
          </ul>
        }
      </div>
    )
  }
}

PageNav.propTypes = {
  items: PropTypes.array,
  headerHeight: PropTypes.number,
  windowHeight: PropTypes.number,
  activeNavSection: PropTypes.string,
  backgroundColor: PropTypes.string,
  pageNavParent: PropTypes.object,
  pageNavSiblings: PropTypes.array
}

PageNav.defaultProps = {
  items: [],
  headerHeight: 0,
  windowHeight: 0,
  pageNavParent: null,
  pageNavSiblings: null
}

const mapStateToProps = ({ headerHeight, windowHeight, activeNavSection, backgroundColor }) => {
  return { headerHeight, windowHeight, activeNavSection, backgroundColor  }
}

export default connect(mapStateToProps, null)(PageNav)