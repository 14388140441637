import React, { useEffect } from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"
import VisibilitySensor from 'react-visibility-sensor'
import { connect } from 'react-redux'

import styles from './pageHeader.module.scss'
 
const PageHeader = ( props ) => {
  function onChange (isVisible) {    
    // console.log(isVisible)
    props.setHeaderTransparent(isVisible)
  }
  
  useEffect(() => {
    props.setHeaderTransparent(true)  

    return () => {
      props.setHeaderTransparent(false) 
    }
  })

  return (
     <VisibilitySensor onChange={onChange} scrollCheck={true} intervalCheck={false} partialVisibility={true} offset={{top: props.headerHeight + 1, bottom: 0}} scrollThrottle={50}>
      <div className={`${styles.header} ${props.background ? styles.headerWithBackground : ''}`} id={props.id}>
        <div className={styles.wrapper}>
          <div className="limit-width">
            <h1 className={`${styles.title} t-h1 t-color-white`}>
              {RichText.asText(props.title)}
            </h1>
          </div>
          <div className={`cols-wrapper`}>
            <div className={styles.text}>
              <div className={`cols`}>
                <div className={`col-8-md col-7-lg`}>
                  <div className={`t-h3 t-color-white text-body`}>
                    {RichText.render(props.text)}
                  </div>
                  {props.children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  )
}

PageHeader.propTypes = {
  text: PropTypes.array,
  title: PropTypes.array,
  id: PropTypes.string,
  background: PropTypes.object,
  headerHeight: PropTypes.number,
  fixedMode: PropTypes.bool
}

PageHeader.defaultProps = {
  text: [],
  title: [],
  headerHeight: 0,
  id: '',
  background: null,
  fixedMode: false
}

const mapStateToProps = ({ headerHeight, windowHeight }) => {
  return { headerHeight, windowHeight }
}

const mapDispatchToProps = dispatch => {
  return {
    setHeaderTransparent: (val) => dispatch({ type: `SET_HEADERTRANSPARENT`, value: val })
  }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)