import React from 'react'
import { RichText } from 'prismic-reactjs'
import PropTypes from "prop-types"
 
import styles from './commoditiesSection.module.scss'

const CommoditiesSection = ( props ) => {
  if (props.section.primary.section_title === null && props.section.primary.section_text === null) {
    return null
  }

  // console.log(props.section.primary.we_supply)

  return (
    <div className="section" id={props.id}>
      <div className={`cols-wrapper`}>
        <div className="cols">
          <div className={`col-8-md col-5-lg`}>
            {!!props.section.primary.section_title &&
              <h3 className={`t-h2 section-title t-color-white ${props.color}`}>{RichText.asText(props.section.primary.section_title)}</h3>
            }
          </div>
        </div>
      </div>
      <div className={`cols-wrapper`}>
        <div className="cols">
          <div className={`col-5-md col-5-lg`}>
            {!!props.section.primary.section_text &&
              <div className={`t-body text-body t-color-white`}>
                {RichText.render(props.section.primary.section_text)}
              </div>
            }
          </div>
          <div className={`col-4-md col-3-lg push-1-lg ${styles.supplyColumn}`}>
            {props.section.primary.we_supply && RichText.asText(props.section.primary.we_supply) !== '' &&
              <div className={`t-body text-body t-color-white`}>
                <p className="t-strong">WE SUPPLY</p>
                {RichText.render(props.section.primary.we_supply)}
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

CommoditiesSection.propTypes = {
  section: PropTypes.object,
  color: PropTypes.string
}

CommoditiesSection.defaultProps = {
  section: {},
  color: "color-blue"
}
 
export default CommoditiesSection